import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { DropdownTab } from '../../components/DropdownTab/DropdownTab';
import { Dropdown } from '../../components/Dropdown/Dropdown';
import { StyledContainer, StyledScrollableTableRows } from '../../components/Table/TableContainer';
import { SortType, TableHeader } from '../../components/Table/TableHeader';
import { RowLabel, StyledTableRows, TableRowType } from '../../components/Table/TableRows';
import { OutlinedTableRow } from '../../components/Table/OutlinedTableRow';
import { GamePlanRecallRows } from './RecallTable/RecallRows';
import { Button } from '../../components/Button/Button';
import { StyledModal } from '../../components/StyledModal/StyledModal';
import { IGrowerResponse } from '../../logic/Models/Responses/GrowerResponse';
import { connect, ConnectedProps } from 'react-redux';
import { notification, Tooltip } from 'antd';
import { AppDispatch, RootState } from '../../logic/store/Store';
import { getPlans, launchExistingPlan, launchOrderGamePlan, downloadPlanPdf, deletePlan, downloadMaxscriptPdf } from '../../logic/store/Grower/PlanThunks';
import { IPlanDownloadRequest, IPlanLaunchRequest } from '../../logic/Models/Requests/PlanRequest';
import { clearGamePlanLaunchUrl, clearPlanCompetitiveAllowance } from '../../logic/store/Grower/GrowerSlice';
import { clearSelectedFields, setRecallSort, setSelectedPlanId, UISort } from '../../logic/store/UI/UISlice';
import { IPlanResponse, PlanType } from '../../logic/Models/Responses/PlanResponse';
import { dynamicSort, SortDirection } from '../../logic/Utility/Utils';
import { makeDispatch } from '../../logic/Utility/Utils';
import { DownloadModal } from './DownloadModal';
import { InviteModal } from './InviteModal';
import { IPlanCoverConfig } from '../../logic/Models/Requests/PlanRequest';
import { TableRow } from '../../components/Table/TableRow';
import { inviteContact } from '../../logic/store/Grower/GrowerThunks';
import { unwrapResult } from '@reduxjs/toolkit';
import { hasEffectivePermission, getCurrentActingUser } from '../../logic/store/User/AuthSlice';
import { UserPermission } from '../../logic/Models/Responses/UserPermissions';
import { clearSelectedProductPlanId, selectProductGamePlanId } from '../../logic/store/Plans/ProductGamePlanSlice';
import { getCropEdgeSsoLaunchUrl, redirectToGhxLogisticsSso } from '../../logic/store/User/UserInfoSlice';
import { downloadVRSPdf, resetPlantingPlanSummaries } from '../../logic/store/Grower/PlantingPlanThunks';
import { getFarms } from '../../logic/store/Grower/FarmThunks';
import { useAmplitudePassthrough } from '../../logic/Utility/useAmplitude';
import { useScopedSession } from '../../tracing/session';
import { EventStructureTags } from '../../tracing/EventTagNames';
import { EventNavigationNames, EventRecallNames } from '../../tracing/EventNames';

const StyledRecallContainer = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	padding-right: 5%;
	padding-left: 10%;
	padding-top: 2.5%;
	padding-bottom: 2.5%;
	flex-direction: column;
`;
const RecallBody = styled.div`
	margin-top: 15px;
	overflow: hidden;
	height: 100%;
`;
type Tabs = 'Plans';
const GHXheaderColumns: { [key in Tabs]: string[] } = {
	'Plans': [
		'Status',
		'Type',
		'Name',
		'Last Updated',
		'Corn Price/Acre',
		'Soy Price/Acre',
		'Total'
	]
};
const GHheaderColumns: { [key in Tabs]: string[] } = {
	'Plans': [
		'Status',
		'Type',
		'Name',
		'Last Updated',
	]
};
const headersToKeys: { [key: string]: keyof IPlanResponse | undefined; } = {
	'Status': 'Status',
	'Type': 'Type',
	'Name': 'Name',
	'Last Updated': 'Modified',
};
type PlanResponseKeys = keyof IPlanResponse;
const keysToHeaders: Partial<{ [key in PlanResponseKeys]: string | undefined; }> = {
	Status: 'Status',
	Type: 'Type',
	Name: 'Name',
	Modified: 'Last Updated'
};

// Be EXTREMELY careful moving these numbers around/changing them, it will easily break how it displays
// on iPad vs Desktop or vice versa
// Note that these are the same numbers being used for both the GH and GHX header columns
const gamePlanSpacing = '10% 15% 15% 15% 8% 8% 15%';
const tableRowClass = 'padding-small height-small';

interface IRecallProps
{
	selectedGrower: IGrowerResponse;
	importOrder: ( year: string) => void;
}

const StyledTableRow = styled(TableRow)`
	padding: 0 1.5%;
	height: 50px;
`;

const StyledOrderRow = styled(TableRow)`
	padding: 0 1.5%;
	height: 50px;
	border: 1px solid;
`;

const RecallStyledTableRows = styled(StyledTableRows)`
	margin-top: 3%;
`;

const RecallStyledScrollableTableRows = styled(StyledScrollableTableRows)`
	overflow-y: auto;
	height: 90%;
`;

export const RecallComponent = (props: IRecallProps & PropsFromRedux) => 
{
	const {
		selectedGrower,
		CanSeeCompetitiveAllowance,
		CurrentCropYear,
		IsGhx,
		IsLoadingFarms,
		IsLoadingPlanDelete,
		IsLoadingPlanLaunch,
		IsLoadingPlans,
		RecallSort,
		SelectedFieldIds,
		SelectedPlanId,
		UserIsGrower,		
		UserSeesEnogenTheme,
		ClearLaunchUrl,
		ClearProductPlan,
		ClearCompetitiveAllowance,
		DeletePlan,
		DownloadPlan,
		DownloadMaxscriptPdf,
		DownloadVRSPdf,
		GetPlansForSelectedGrower,
		LaunchCropEdgeSso,
		LaunchExisting,
		LaunchOrderPlan,
		PullFarmFieldTree,
		ResetPlantingPlans,
		ClearSelectedFields,
		SelectProductGamePlanId,
		SetRecallSort,
		SetSelectedPlanId,
		importOrder,
		SsoToLogistics,
	} = props;
	const history = useHistory();

	const theme = useTheme();

	const [plans, setPlans] = useState(selectedGrower.Plans || []);
	const [selectedTab, setSelectedTab] = useState<Tabs>('Plans');
	const [isLaunchModalOpen, setLaunchModalOpen] = useState<boolean>(false);
	const [isDownloadModalOpen, setDownloadModalOpen] = useState<boolean>(false);
	const [isInviteModalOpen, setInviteModalOpen] = useState<boolean>(false);
	const [planIdToDownload, setPlanIdToDownload] = useState<string>(undefined);
	const [planTypeToDownload, setPlanTypeToDownload] = useState<PlanType>(undefined);
	const [selectedYears, setSelectedYears] = useState<{ [key in Tabs]: string }>({
		'Plans': CurrentCropYear
	});
	const [acceptedWorkOrders, setAcceptedWorkOrders] = useState(plans.filter(item => item.Status === 'Accepted'));
	const [draftWorkOrders, setDraftWorkOrders] = useState(plans.filter(item => item.Status === 'Draft'));
	const [plannedWorkOrders, setPlannedWorkOrders] = useState(plans.filter(item => item.Status === 'Planned'));
	const [orderedPlan, setOrderedPlan] = useState(plans.filter(item => item.Status === 'Order'));

	const growerFields = selectedGrower.Farms?.flatMap(farm => farm.Fields) ?? [];

	const [startFieldPlanDisabled, setStartFieldPlanDisabled] = useState(!growerFields.length);
	const [startOrderPlanDisabled, setStartOrderPlanDisabled] = useState(!growerFields.length || !selectedGrower.SalesforceId);
	const [startProductPlanDisabled, setStartProductPlanDisabled] = useState(!growerFields.length || !selectedGrower.FoundationId);

	const [isResetPlantingPlansModalOpen, SetIsResetPlantingPlansModalOpen] = useState<boolean>(false);

	// Column headers based on the user type
	const headerColumns = IsGhx ? GHXheaderColumns : GHheaderColumns;

	useEffect(() =>
	{
		if (!IsLoadingFarms)
		{
			PullFarmFieldTree({ growerId: selectedGrower.Id	});
		}
	}, []);

	useEffect(() =>
	{
		setAcceptedWorkOrders(plans.filter(item => item.Status === 'Accepted'));
		setDraftWorkOrders(plans.filter(item => item.Status === 'Draft'));
		setPlannedWorkOrders(plans.filter(item => item.Status === 'Planned'));
		setOrderedPlan(plans.filter(item => item.Status === 'Order'));
		
		// Clear any Competitve Allowances if we had a previously selected plan
		if (SelectedPlanId)
		{
			ClearCompetitiveAllowance({planId: SelectedPlanId, growerId: selectedGrower.Id});
		}

		// Clear the selected plan ID
		SetSelectedPlanId(undefined);
	}, [plans]);
	
	const getToolTipValue = (doesGrowerHaveFields:boolean ) : string =>
	{
		let toolTipText: string;
				
		if ((doesGrowerHaveFields) && (selectedGrower.SalesforceId) ) 
			toolTipText = '';
		else if (selectedGrower.SalesforceId)
			toolTipText = 'Grower must have fields to start an Order Plan';
		else if (doesGrowerHaveFields)
			toolTipText = 'Grower has no Salesforce ID. Please create a grower account in CropEdge to use this feature.';
		else 
			toolTipText = 'Grower has no Salesforce ID. Please create a grower account in CropEdge to use this feature.\nGrower must have fields to start an Order Plan';
		return toolTipText;
	};

	const [startOrderPlanToolTipText, setStartOrderPlanToolTip] = useState(getToolTipValue((growerFields && growerFields.length > 0)));
	
	useEffect(() =>
	{
		const doesGrowerHaveFields = growerFields && growerFields.length > 0;
		setStartFieldPlanDisabled(!doesGrowerHaveFields);
		setStartOrderPlanDisabled(!doesGrowerHaveFields || !selectedGrower.SalesforceId);
		//change tooltip for setOrder
		setStartOrderPlanToolTip( getToolTipValue(doesGrowerHaveFields) );
		setStartProductPlanDisabled(!selectedGrower.FoundationId);
	}, [growerFields, plans]);

	useEffect(() =>
	{
		if (!IsLoadingPlans)
		{
			GetPlansForSelectedGrower(selectedYears[selectedTab]);
		}
	}, [selectedTab, selectedYears]);

	useEffect(() =>
	{
		if (selectedGrower.Plans)
		{
			sortPlans(selectedGrower.Plans);
		}
	}, [selectedGrower.Plans]);

	useEffect(() =>
	{
		if (!IsLoadingPlanLaunch && selectedGrower?.GamePlanLaunchUrl)
		{
			// Clean the url so that on a return it will not continually launch the url over and over
			ClearLaunchUrl(selectedGrower.Id);

			// And redirect to the launch URL
			window.location.href = selectedGrower.GamePlanLaunchUrl;
		}
	}, [selectedGrower?.GamePlanLaunchUrl]);

	const onLaunchOrderPlan = () =>
	{
		if (!IsLoadingPlanLaunch) 
		{
			LaunchOrderPlan({
				FieldIds: SelectedFieldIds,
				ReturnUrl: window.location.href,
			});
			setLaunchModalOpen(false);
		}
	};

	const session = useScopedSession(Recall.name, {}, {
		[EventStructureTags.PageContext]: 'recall',
		[EventStructureTags.PageUrl]: window.location.toString()
	});

	const onLaunchFieldPlan = useAmplitudePassthrough(session, EventNavigationNames.To, () =>
	{
		ClearSelectedFields();
		// Clear any Competitve Allowances if there was a previously selected plan
		if (SelectedPlanId)
		{
			ClearCompetitiveAllowance({planId: SelectedPlanId, growerId: selectedGrower.Id});
		}
		SetSelectedPlanId(undefined);
		setLaunchModalOpen(false);
		history.push('/fieldactivities/fieldplan');
	}, [ClearSelectedFields, SetSelectedPlanId, ClearCompetitiveAllowance, setLaunchModalOpen, history], {
		[EventStructureTags.DestinationName]: 'field_plan',
		[EventStructureTags.DestinationUrl]: '/fieldactivities/fieldplan'
	});

	const sortPlans = (plansToSort = []) =>
	{
		const { field, sortDirection } = RecallSort;
		if (sortDirection === 'none')
		{
			setPlans([...selectedGrower.Plans]);
		}
		else
		{
			setPlans(
				[...plansToSort].sort(dynamicSort(field, sortDirection as SortDirection))
			);
		}
	};

	const onSort = (sort: SortType, header: string) =>
	{
		SetRecallSort({ field: headersToKeys[header], sortDirection: sort });
	};

	const onLaunchExistingPlan = useAmplitudePassthrough(session, EventNavigationNames.To, (planId: string) =>
	{
		LaunchExisting({
			planId,
			ReturnUrl: window.location.href,
			history
		});
	}, [LaunchExisting, history], {
		[EventStructureTags.DestinationName]: 'existing_field_plan'
	});

	const openDownloadModal = (planId: string, planType: PlanType) =>
	{
		setInviteModalOpen(false);
		setPlanTypeToDownload(planType);
		setPlanIdToDownload(planId);
		// If Product or Field Plan, skip the presenter modal
		if (planType === 'ProductGamePlan' || planType === 'FieldGamePlan')
		{
			setDownloadModalOpen(false);
			onDownloadPlan(undefined, planId);
		}
		else if (planType == 'Order' || planType == 'OrderGamePlan')
		{
			setDownloadModalOpen(false);
			setPlanIdToDownload(undefined);
			DownloadMaxscriptPdf();
		}
		else
		{
			setDownloadModalOpen(true);
		}
	};

	const onDownloadPlan = useAmplitudePassthrough(session, EventRecallNames.DownloadPlanPdf, (coverConfig?: IPlanCoverConfig, planIdForDownload?: string) =>
	{
		// If neither a plan was passed in nore are there any accepted work orders to default to, bail out
		if (!planIdForDownload)
		{
			if (!planIdToDownload && (!acceptedWorkOrders.length || !acceptedWorkOrders[0]))
				return;
		}

		DownloadPlan({ PlanId: planIdToDownload ? planIdToDownload : planIdForDownload ? planIdForDownload : acceptedWorkOrders[0].Id, CoverConfig: coverConfig });

		setDownloadModalOpen(false);
		setPlanIdToDownload(undefined);
	}, [acceptedWorkOrders]);

	const openInviteModal = (planId: string, planType: PlanType) =>
	{
		setPlanTypeToDownload(planType);
		setInviteModalOpen(true);
		setPlanIdToDownload(planId);
	};

	useEffect(() =>
	{
		sortPlans(plans);
	}, [RecallSort]);

	// Previous crop year and current
	const cropYearOptions: string[] = [(Number.parseInt(CurrentCropYear) - 1).toString(), CurrentCropYear];
	const currentlySelectedCropYear = selectedYears[selectedTab];
	const isCropYearOld = Number.parseInt(currentlySelectedCropYear) < Number.parseInt(CurrentCropYear);
	const sendAction = (IsGhx && props.GrowerHasSalesforceId && props.UserHasFoundationId) ? openInviteModal : openDownloadModal;
	
	const onDownloadVRSPDF = useAmplitudePassthrough(session, EventRecallNames.DownloadVrsPdf, () => 
	{
		DownloadVRSPdf({
			GrowerId: selectedGrower.Id,
			SelectedYear: currentlySelectedCropYear,
			CropPlans: []
		});
	}, [selectedGrower, currentlySelectedCropYear]);

	const isPlantingPlansResetButtonDisabled = () =>
	{
		// Must be current year
		if (isCropYearOld)
		{
			return true;
		}

		// Must have fields
		const doesGrowerHaveFields = growerFields && growerFields.length > 0;
		if (!doesGrowerHaveFields)
		{
			return true;
		}

		// If we have an order + fields, then we will have a Planting Plans for fields
		const doesGrowerHaveOrder = orderedPlan && orderedPlan.length > 0;
		if (doesGrowerHaveOrder && doesGrowerHaveFields)
		{
			return false;
		}

		// If we don't have an order, but we have fields, and we have an accepted field gameplan, then we have Planting Plans
		const doesGrowerHaveFieldGamePlan = acceptedWorkOrders && acceptedWorkOrders.length > 0 &&  acceptedWorkOrders[0].Type === 'FieldGamePlan';
		if (doesGrowerHaveFieldGamePlan && !doesGrowerHaveOrder)
		{
			return false;
		}

		return true;
	};

	const resetPlantingPlans = useAmplitudePassthrough(session, EventRecallNames.ResetPlantingPlans,() =>
	{
		SetIsResetPlantingPlansModalOpen(false);
		ResetPlantingPlans({Year: currentlySelectedCropYear });
	}, [currentlySelectedCropYear]);

	const selectGamePlanId = (planId: string) =>
	{
		// Clear Competitve Allowances
		ClearCompetitiveAllowance({planId: planId, growerId: selectedGrower.Id});

		SetSelectedPlanId(planId);
	};

	const ghxOpenOrderInLogistics = (salesforceOrderId: string) =>
	{
		SsoToLogistics({ salesforceOrderId: salesforceOrderId });
	};

	return (<div style={{ width: '100%', height: '100%', zIndex: 998 }}>
		<div style={{
			position: 'absolute',
			marginLeft: '10%',
			marginTop: '2.5%',
			paddingBottom: '5%',
			fontWeight: theme.fontWeights.bold,
			fontSize: theme.fontSizes.large
		}}>{selectedGrower?.Name} {IsGhx ? 'GHX' : UserSeesEnogenTheme ? '' : 'GH'} Proposal List</div>
		<StyledRecallContainer className='RecallContainer'>
			<div className='new-game-plan-container' style={{ display: 'flex', justifyContent: 'flex-end' }}>
				{
					!UserIsGrower &&
					<Tooltip title={isCropYearOld ? 'Select the current crop year to import an Order.' : ''} placement='left'>
						<div style={{ marginRight: '1%' }}>
							<Button
								className='import-order-button'
								variant='outlined'
								hidden = {false} 
								onClick={() => importOrder(currentlySelectedCropYear)}
								disabled={isCropYearOld}>
								Import Order
							</Button>
						</div>
					</Tooltip>
				}
				{
					!UserIsGrower &&
					<Tooltip title={isCropYearOld ? 'Select the current crop year to reset Planting Plans.' : ''} placement='left'>
						<div style={{ marginRight: '1%' }}>
							<Button
								className='reset-plantingplans-button'
								variant='outlined'
								hidden = {false} 
								onClick={() => SetIsResetPlantingPlansModalOpen(true)}
								disabled={isPlantingPlansResetButtonDisabled()}>
									Reset Planting Plans
							</Button>
						</div>
					</Tooltip>
				}
				<Tooltip title={isCropYearOld ? 'Select the current crop year to create new Proposals' : ''} placement='left'>
					<div style={{ marginRight: '1%' }}>
						<Button
							className='new-game-plan-button'
							variant='outlined'
							onClick={() => setLaunchModalOpen(true)}
							disabled={isCropYearOld || (orderedPlan && orderedPlan.length > 0)}>
								New Proposal
						</Button>
					</div>
				</Tooltip>
				<StyledModal title='Choose the type of Proposal to create' open={isLaunchModalOpen} onCancel={() => setLaunchModalOpen(false)}>
					<div style={{ display: 'grid' }}>
						<Tooltip title={startFieldPlanDisabled ? 'Grower must have fields to start a Field Proposal.' : ''} placement='left'>
							<div><Button className='launch-field-game-plan-button' variant='outlined'
								disabled={startFieldPlanDisabled}
								style={{ width: '100%' }}
								onClick={onLaunchFieldPlan}
							>Field Proposal</Button></div>
						</Tooltip>
						{/* <Tooltip title={startOrderPlanToolTipText } placement='left'>
						
							<div><Button className='launch-order-game-plan-button' variant='outlined'
								disabled={startOrderPlanDisabled}
								style={{ marginTop: '2%', width: '100%' }}
								onClick={onLaunchOrderPlan}>Order Plan</Button></div>
						</Tooltip> */}
						<Tooltip title={startProductPlanDisabled ? 'Grower has no Foundation ID. Please create a grower account in CropEdge to use this feature.' : ''} placement='left'>
							<Link to={'/fieldactivities/productplan'} onClick={(e) => startProductPlanDisabled ? e.preventDefault() : ClearProductPlan()} style={{ cursor: startProductPlanDisabled ? 'default' : 'pointer' }}>
								<Button className='launch-product-game-plan-button' variant='outlined' style={{ marginTop: '2%', width: '100%' }} disabled={startProductPlanDisabled}>Acre Proposal</Button>
							</Link>
						</Tooltip>
					</div>
				</StyledModal>
			</div>
			<div style={{ display: 'flex', zIndex: 1 }}>
				<DropdownTab selected={selectedTab === 'Plans'}
					containerStyle={{ flexGrow: 1, width: '50%', marginRight: '15px' }}
					label='Plan'
					onClick={() => setSelectedTab('Plans')}>
					{selectedTab === 'Plans' && <Dropdown
						className='game-plan-crop-year-dropdown'
						options={cropYearOptions}
						selected={selectedYears['Plans']}
						onSelect={(year) => setSelectedYears({
							...selectedYears,
							['Plans']: year
						})} />}
				</DropdownTab>
			</div>
			<RecallBody className='recall-body' id='recall-body'>
				{selectedTab === 'Plans' && <StyledContainer style={{ height: '100%' }}>
					<TableHeader className='padding-small' headers={headerColumns['Plans']}
						initialSorts={{ [keysToHeaders[RecallSort.field]]: RecallSort.sortDirection }}
						defaultSort={{ headerName: 'Last Updated', direction: 'descending' }}
						sortActions={{
							'Last Updated': (order: SortType) => onSort(order, 'Last Updated'),
							'Name': (order: SortType) => onSort(order, 'Name'),
							'Type': (order: SortType) => onSort(order, 'Type')
						}}
						columnSpacing={gamePlanSpacing} />
					<RecallStyledScrollableTableRows>
						{
							!acceptedWorkOrders.length && !orderedPlan.length ?
								<OutlinedTableRow id='none-status-accepted' className={`${tableRowClass} centered`} label='Grower Accepted Proposal'>
									There is currently no accepted Proposal. Change the status of a draft or presented Proposal below to accept it.
								</OutlinedTableRow>
								:
								<></>
						}
						{!!orderedPlan.length &&
							<RecallStyledTableRows>
								{GamePlanRecallRows({
									data: orderedPlan,
									hasOrderedPlan: orderedPlan && orderedPlan.length > 0,
									currentlySelectedId: orderedPlan[0]?.Id,
									onLaunchExisting: onLaunchExistingPlan,
									selectProductGamePlanId: SelectProductGamePlanId,
									onClickDownload: openDownloadModal,
									onClickDownloadVRS: onDownloadVRSPDF,
									onClickInvite: sendAction,
									deletePlan: DeletePlan,
									isGhx: IsGhx,
									canSeeCompetitiveAllowance: CanSeeCompetitiveAllowance,
									enableShoppingCart: (props.GrowerHasSalesforceId && props.UserHasFoundationId),
									isLoadingDelete: IsLoadingPlanDelete,
									growerHasZip: props.GrowerHasZip,
									currentlySelectedCropYear: selectedYears[selectedTab],
									launchCropEdgeSso: LaunchCropEdgeSso,
									selectGamePlanId: selectGamePlanId,
									launchLogisticsSso: ghxOpenOrderInLogistics,
								}).map(
									(row: TableRowType<IPlanResponse>, index) =>
										<div className={`order${index + 1}`} key={`order-id-${row.data.Id}`}>
											<StyledOrderRow Id={row.data.Id} items={row.items} columnSpacing={gamePlanSpacing} hideExpand />
										</div>
								)}
							</RecallStyledTableRows>
						}
						{!!acceptedWorkOrders.length &&
							<RecallStyledTableRows>
								<RowLabel>Grower Accepted Proposal</RowLabel>
								{GamePlanRecallRows({
									data: acceptedWorkOrders,
									hasOrderedPlan: orderedPlan && orderedPlan.length > 0,
									currentlySelectedId: acceptedWorkOrders[0]?.Id,
									onLaunchExisting: onLaunchExistingPlan,
									selectProductGamePlanId: SelectProductGamePlanId,
									onClickDownload: openDownloadModal,
									onClickDownloadVRS: onDownloadVRSPDF,
									onClickInvite: sendAction,
									deletePlan: DeletePlan,
									isGhx: IsGhx,
									canSeeCompetitiveAllowance: CanSeeCompetitiveAllowance,
									enableShoppingCart: (props.GrowerHasSalesforceId && props.UserHasFoundationId),
									isLoadingDelete: IsLoadingPlanDelete,
									growerHasZip: props.GrowerHasZip,
									currentlySelectedCropYear: selectedYears[selectedTab],
									launchCropEdgeSso: LaunchCropEdgeSso,
									selectGamePlanId: selectGamePlanId,
									launchLogisticsSso: ghxOpenOrderInLogistics,
								}).map(
									(row: TableRowType<IPlanResponse>, index) =>
										<div className={`accepted-order${index + 1}`} key={`accepted-order-id-${row.data.Id}`}>
											<StyledTableRow Id={row.data.Id} items={row.items} columnSpacing={gamePlanSpacing} hideExpand />
										</div>
								)}
							</RecallStyledTableRows>}
						{!plannedWorkOrders.length && <OutlinedTableRow id='none-status-Planned' className={`${tableRowClass} centered`} label='Presented Proposal'>
							There are currently no presented Proposals. Change the status of a draft Proposal below to plan it.
						</OutlinedTableRow>}
						{!!plannedWorkOrders.length &&
							<RecallStyledTableRows >
								<RowLabel>Presented Proposals</RowLabel>
								{GamePlanRecallRows({
									data: plannedWorkOrders,
									hasOrderedPlan: orderedPlan && orderedPlan.length > 0,
									currentlySelectedId: acceptedWorkOrders[0]?.Id,
									onLaunchExisting: onLaunchExistingPlan,
									selectProductGamePlanId: SelectProductGamePlanId,
									onClickDownload: openDownloadModal,
									onClickDownloadVRS: onDownloadVRSPDF,
									onClickInvite: sendAction,
									deletePlan: DeletePlan,
									isGhx: IsGhx,
									canSeeCompetitiveAllowance: CanSeeCompetitiveAllowance,
									enableShoppingCart: false,
									isLoadingDelete: IsLoadingPlanDelete,
									growerHasZip: props.GrowerHasZip,
									currentlySelectedCropYear: selectedYears[selectedTab],
									launchCropEdgeSso: LaunchCropEdgeSso,
									selectGamePlanId: selectGamePlanId,
									launchLogisticsSso: ghxOpenOrderInLogistics,
								}).map(
									(row: TableRowType<IPlanResponse>, index) =>
										<div className={`planned-order${index + 1}`} key={`planned-order-id-${row.data.Id}`}>
											<StyledTableRow Id={row.data.Id} items={row.items} columnSpacing={gamePlanSpacing} hideExpand />
										</div>
								)}
							</RecallStyledTableRows>}
						{!draftWorkOrders.length && <OutlinedTableRow id='none-status-Planned' className={`${tableRowClass} centered`} label='My Drafts'>
							There are currently no draft Proposals.
						</OutlinedTableRow>}
						{!!draftWorkOrders.length &&
							<RecallStyledTableRows >
								<RowLabel>My Drafts</RowLabel>
								{GamePlanRecallRows({
									data: draftWorkOrders, currentlySelectedId: acceptedWorkOrders[0]?.Id, onLaunchExisting: onLaunchExistingPlan,
									hasOrderedPlan: orderedPlan && orderedPlan.length > 0,
									selectProductGamePlanId: SelectProductGamePlanId,
									onClickDownload: openDownloadModal,
									onClickDownloadVRS: onDownloadVRSPDF,
									onClickInvite: openInviteModal,
									deletePlan: DeletePlan,
									isGhx: IsGhx,
									canSeeCompetitiveAllowance: CanSeeCompetitiveAllowance,
									enableShoppingCart: false,
									isLoadingDelete: IsLoadingPlanDelete,
									growerHasZip: props.GrowerHasZip,
									currentlySelectedCropYear: selectedYears[selectedTab],
									launchCropEdgeSso: LaunchCropEdgeSso,
									selectGamePlanId: selectGamePlanId,
									launchLogisticsSso: ghxOpenOrderInLogistics,
								}).map(
									(row: TableRowType<IPlanResponse>, index) =>
										<div className={`draft-order${index + 1}`} key={`draft-order-id-${row.data.Id}`}>
											<StyledTableRow Id={row.data.Id} items={row.items} columnSpacing={gamePlanSpacing} hideExpand />
										</div>
								)}
							</RecallStyledTableRows>}
						<DownloadModal
							open={isDownloadModalOpen}
							onCancel={() => setDownloadModalOpen(false)}
							onSubmit={onDownloadPlan}
							downloadPlanType={planTypeToDownload}
						/>
						<InviteModal
							growerId={selectedGrower.Id}
							open={isInviteModalOpen}
							onCancel={() => setInviteModalOpen(false)}
							onInvite={async (email, name, contactId) =>
							{
								setInviteModalOpen(false);
								const resultThunk = await props.InviteContact({
									growerId: selectedGrower.Id,
									name,
									email,
									contactId
								});
								const result = unwrapResult(resultThunk);
								if (result.status === 'PENDING')
								{
									notification.success({
										message: 'The invitation was sent successfully.',
										placement: 'topRight'
									});
								}
								else
								{
									notification.error({
										message: 'The invitation failed to send.',
										placement: 'topRight'
									});
								}
							}}
							onDownload={() => openDownloadModal(planIdToDownload, planTypeToDownload)}
						/>
						<StyledModal
							title={'Reset Planting Plans Across All Fields for VRS'}
							open={isResetPlantingPlansModalOpen}
							onCancel={() => SetIsResetPlantingPlansModalOpen(false)}
						>
							<span>This will overwrite any edits made to the VRS. Would you like to proceed?</span>
							<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '30px' }}>
								<Button
									className='cancel-resetPlantingPlans-button'
									variant='outlined'
									onClick={() => SetIsResetPlantingPlansModalOpen(false)}
								>Cancel</Button>
								<Button
									className='proceed-resetPlantingPlans-button'
									variant='dark'
									style={{ marginLeft: 20 }}
									onClick={() => resetPlantingPlans()}
								>Proceed</Button>
							</div>
						</StyledModal>
					</RecallStyledScrollableTableRows>
				</StyledContainer>}
			</RecallBody>
		</StyledRecallContainer>
	</div>);
};
const mapStateToProps = (state: RootState) => ({
	CurrentCropYear: state.crops.CropYear,
	ErrorMessage: state.grower.errorMessage,
	GrowerHasSalesforceId: !!state.grower.Growers.find(g => g.Id === state.ui.SelectedGrowerId)?.SalesforceId,
	GrowerHasZip: !!state.grower.Growers.find(g => g.Id === state.ui.SelectedGrowerId)?.Zip,
	IsError: state.grower.isError,
	IsGhx: hasEffectivePermission(state, UserPermission.CanSeePricing),
	CanSeeCompetitiveAllowance: hasEffectivePermission(state, UserPermission.CanSeeCompetitiveAllowance, true),
	IsLoadingFarms: state.grower.isLoadingFarms,
	IsLoadingPlanDelete: state.grower.isLoadingPlanDelete,
	IsLoadingPlanLaunch: state.grower.isLoadingPlanLaunch,
	IsLoadingPlans: state.grower.isLoadingPlans,
	RecallSort: state.ui.RecallSort,
	SelectedFieldIds: state.ui.SelectedFields,
	SelectedPlanId: state.ui.SelectedPlanId,
	UserHasFoundationId: !!getCurrentActingUser(state).FoundationId,
	UserSeesEnogenTheme: hasEffectivePermission(state, UserPermission.CanSeeEnogenTheme),
	UserIsGrower: hasEffectivePermission(state, UserPermission.IsGrower),
});

const mapDispatchToProps = (dispatch: AppDispatch) =>
{
	return {
		ClearLaunchUrl: (growerId: string) => dispatch(clearGamePlanLaunchUrl(growerId)),
		ClearProductPlan: () => dispatch(clearSelectedProductPlanId()),
		DeletePlan: (planId: string) => dispatch(deletePlan({ planId })),
		DownloadPlan: (req: IPlanDownloadRequest) => dispatch(downloadPlanPdf(req)),
		DownloadVRSPdf: makeDispatch(dispatch, downloadVRSPdf),
		GetPlansForSelectedGrower: (year: string) => dispatch(getPlans({ cropYear: year })),
		InviteContact: makeDispatch(dispatch, inviteContact),
		LaunchCropEdgeSso: makeDispatch(dispatch, getCropEdgeSsoLaunchUrl),
		LaunchExisting: makeDispatch(dispatch, launchExistingPlan),
		LaunchOrderPlan: (req: IPlanLaunchRequest) => dispatch(launchOrderGamePlan(req)),
		PullFarmFieldTree: makeDispatch(dispatch, getFarms),
		ResetPlantingPlans: makeDispatch(dispatch, resetPlantingPlanSummaries),
		SelectProductGamePlanId: makeDispatch(dispatch, selectProductGamePlanId),
		SetRecallSort: (sort: UISort<IPlanResponse>) => dispatch(setRecallSort(sort)),
		SetSelectedPlanId: makeDispatch(dispatch, setSelectedPlanId),
		ClearSelectedFields: makeDispatch(dispatch, clearSelectedFields),
		DownloadMaxscriptPdf: makeDispatch(dispatch, downloadMaxscriptPdf),
		ClearCompetitiveAllowance: makeDispatch(dispatch, clearPlanCompetitiveAllowance),
		SsoToLogistics: makeDispatch(dispatch, redirectToGhxLogisticsSso),
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const Recall = connector(RecallComponent);