import React, { useEffect, useMemo, useState } from 'react';
import { IFieldResponse } from '../../Models/Responses/FieldResponse';
import { PortalPopUp } from './PortalPopUp';
import { ReactComponent as AcresSvg } from '../Icons/Acres.svg';
import { ReactComponent as SoilSvg } from '../Icons/Soil.svg';
import { ReactComponent as MagnifyingGlass } from '../../../assets/images/MagnifyingGlass.svg';
import { ReactComponent as SelectBoundary } from '../Icons/SelectBoundary.svg';
import { Button } from '../../../components/Button/Button';
import styled, { useTheme } from 'styled-components';
import { dynamicSort } from '../../Utility/Utils';
import { IGrowthStageData, IWeatherSnapshot } from '../Map';
import { Modal, Tabs } from 'antd';
import Chart from 'react-google-charts';
import { ScaleLoader } from 'react-spinners';
import { useSelector } from 'react-redux';
import { featureFlagSelector, Features, getFeatureFlag } from '../../store/User/ConfigSlice';

export interface IFieldSoilData
{
	Acres: string;
	Color: string;
	MapUnit: string;
	OverallNccpi: string;
	PercentOfTotal: string;
	SoilType: string;
}

const SeedLabelRow = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 2%;
`;

const SeedHybridRow = styled.div`
	display: flex;
	flex-direction: row;
	padding-left: 4%;
	padding-right: 2%;
`;

const SeedHybridParentRow = styled.div`
	display: flex;
	flex-direction: row;
	padding-left: 2%;
	padding-right: 2%;
	padding-bottom: 2px;
`;

const ColoredDot = styled.div<{ color: string }>`
	&:before {
		content:"";
		width:10px;
		height:10px;
		border-radius:50%;
		background: ${props => props.color};
		display: flex;
		margin-top: 2px;
	}
`;

interface IMapPopUpProps
{
	reference: HTMLDivElement | undefined;
	field: IFieldResponse;
	farmName: string;
	showSoilMapButton: boolean;
	fieldSoilData: IFieldSoilData[];
	loadSoilMap: (field: IFieldResponse) => Promise<void>;
	showFieldDetails: () => void;
	weatherData: IWeatherSnapshot[];
	growthStageData: IGrowthStageData;
}

interface IFieldSeedRow
{
	Acres: number;
	Treatment: string;
}

export const MapPopUp = (props: IMapPopUpProps) =>
{
	const { reference, field, farmName, showSoilMapButton, fieldSoilData, loadSoilMap, showFieldDetails, weatherData, growthStageData } = props;
	const [fieldSeeds, setFieldSeeds] = useState<{ [key: string]: IFieldSeedRow[] }>({});
	const [showWeatherData, setShowWeatherData] = useState<boolean>(false);
	const featureFlags = useSelector(featureFlagSelector);
	const showWeatherPopup = getFeatureFlag(featureFlags, Features.WeatherPopup);

	const theme = useTheme();

	const growthStageByDate = useMemo(() => 
	{ 
		const lookup = Object.fromEntries(Object.entries(growthStageData?.GrowthStages ?? {})
			.map(([stage, date]) => [new Date(date).toDateString(), stage]));
		if(growthStageData?.PlantingDate)
		{
			lookup[new Date(growthStageData.PlantingDate).toDateString()] = 'Planting';
		}
		return lookup;
	}, [growthStageData]);

	useEffect(() =>
	{
		if (field && field.Seeds)
		{
			const currentFieldSeeds = {};
			field.Seeds.forEach(fieldSeed =>
			{
				// Sort the seeds
				if (!currentFieldSeeds[fieldSeed.HybridName])
				{
					currentFieldSeeds[fieldSeed.HybridName] = [{ Acres: fieldSeed.Area, Treatment: fieldSeed.Treatment }];
				}
				else
				{
					const foundIndex = currentFieldSeeds[fieldSeed.HybridName].findIndex(t => t.Treatment === fieldSeed.Treatment);
					if (foundIndex > -1)
					{
						return;
					}
					else
					{
						currentFieldSeeds[fieldSeed.HybridName] =
							[...currentFieldSeeds[fieldSeed.HybridName], { Acres: fieldSeed.Area, Treatment: fieldSeed.Treatment }];
					}
				}

			});
			setFieldSeeds({ ...currentFieldSeeds });
		}
	}, [field]);	

	return (
		field ?
			<PortalPopUp className='MapPopUp_Container' reference={reference}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						padding: '5%'
					}}
				>
					<span
						className='MapPopUp_FarmName'
						style={{
							fontSize: theme.fontSizes.small,
							fontWeight: theme.fontWeights.bold,
							paddingBottom: '2%'
						}}
					>
						{farmName}
					</span>
					<span
						className='MapPopUp_FieldName'
						style={{
							fontSize: theme.fontSizes.xLarge,
							fontWeight: theme.fontWeights.bold,
							paddingBottom: '2%'
						}}
					>
						{field.Name}
					</span>
					<span className='MapPopUp_Acreage' style={{ fontSize: theme.fontSizes.small, paddingBottom: '4%' }}>
						{
							showSoilMapButton ?
								<div><AcresSvg style={{ marginRight: 5, position: 'relative', top: 3 }} />{field.Area.toFixed(1)} ac</div>
								:
								<div>Soil Information</div>
						}
					</span>
					<div style={{display:'flex'}}>
						<Button
							className='MapPopUp_SoilMapButton'
							variant='light'
							style={{ display: showSoilMapButton ? 'flex' : 'none', alignItems: 'center' }}
							onClick={async() => await loadSoilMap(field)}
						>
							<SoilSvg style={{ position: 'relative', marginRight: 5, fill: theme.colors.primary }} />
							<span style={{
								fontWeight: theme.fontWeights.normal,
								fontSize: theme.fontSizes.small,
								color: theme.colors.primary,
							}}>
								Soil Map
							</span>
						</Button>
						<Button
							className='MapPopUp_FieldDetailsButton'
							variant='light'
							style={{ display: showSoilMapButton ? 'none' : 'flex', alignItems: 'center' }}
							onClick={showFieldDetails}
						>
							<SelectBoundary style={{ position: 'relative', marginRight: 5, fill: theme.colors.primary }} />
							<span style={{
								fontWeight: theme.fontWeights.normal,
								fontSize: theme.fontSizes.small,
								color: theme.colors.primary,
							}}>
								Field Details
							</span>
						</Button>
						{showWeatherPopup &&
							<Button
								className='MapPopUp_FieldDetailsButton'
								variant='light'
								style={{ display: 'flex', alignItems: 'center' }}
								onClick={() => weatherData?.length && setShowWeatherData(prev => !prev)}
							>
								<MagnifyingGlass style={{ position: 'relative', marginRight: 5, fill: theme.colors.primary }} />
								<span style={{
									fontWeight: theme.fontWeights.normal,
									fontSize: theme.fontSizes.small,
									color: theme.colors.primary,
								}}>
									{(!!weatherData?.length) && <>Weather Data</>}								
									<ScaleLoader color={theme.colors.primary} loading={!weatherData?.length} />
								</span>
							</Button>
						}
					</div>
					{
						showWeatherData && weatherData?.length > 0 ? <Modal
							style={{ 
								top: 20, 
								left: 20,
								right: 20, 
								bottom: 20
							}}
							bodyStyle={{ height: '85vh' }}
							width={'90%'}
							open={showWeatherData}
							onOk={() => setShowWeatherData(false)}
							onCancel={() => setShowWeatherData(false)}
						>
							<Tabs items={[
								{ label: 'Risk', key: 'risk', children:<div style={{display:'relative'}}><Chart 
									chartType="LineChart"
									options={{
										series: [
											{ color: '#1B71E7', visibleInLegend: false },
											{ color: '#1B71E7', visibleInLegend: false },
											{ color: '#1B71E7', visibleInLegend: false },
											{ color: '#1B71E7', visibleInLegend: false },
											{ color: '#1B71E7', visibleInLegend: false },
											{ color: '#1B71E7' },
											{ color: '#ED965D' },
											{ color: '#717171', type: 'bars' },
										],
										intervals: { color: '#0000ff'},
										interval: {
											'i0': { 'style': 'area', 'fillOpacity':0.20, color:'#eb4b4b' },
											'i1': { 'style': 'area', 'fillOpacity':0.20, color:'#F0C355' },
											'i2': { 'style': 'area', 'fillOpacity':0.20, color:'#19A04B' },
											'i3': { 'style': 'area', 'fillOpacity':0.20, color:'#31B4F2' },
											'i4': { 'style': 'area', 'fillOpacity':0.20, color:'#005693' },
										},
										hAxis: { title: 'Date' },
										vAxes: {
											0: {logScale: false, title: 'Crop Stress Index'},
										},
										backgroundColor: '#fff'
									}}
									data={[
										[
											{ type: 'string', label: 'Date' },
											{ type: 'string', role: 'annotation'},
											{ type: 'number', label: 'Fine Soil (VLOW)' },
											{ id: 'i4', type: 'number', label: 'VLOW', role:'interval' },
											{ id: 'i4', type: 'number', label: 'VLOW', role:'interval' },
											{ type: 'number', label: 'Fine Soil (LOW)' },
											{ id: 'i3', type: 'number', label: 'VLOW', role:'interval' },
											{ id: 'i3', type: 'number', label: 'VLOW', role:'interval' },
											{ type: 'number', label: 'Fine Soil (MED)' },
											{ id: 'i2', type: 'number', label: 'VLOW', role:'interval' },
											{ id: 'i2', type: 'number', label: 'VLOW', role:'interval' },
											{ type: 'number', label: 'Fine Soil (MED)' },
											{ id: 'i1', type: 'number', label: 'VLOW', role:'interval' },
											{ id: 'i1', type: 'number', label: 'VLOW', role:'interval' },
											{ type: 'number', label: 'Fine Soil (VLOW)' },
											{ id: 'i0', type: 'number', label: 'VLOW', role:'interval' },
											{ id: 'i0', type: 'number', label: 'VLOW', role:'interval' },
											{ type: 'number', label: 'Fine Soil' },
											{ type: 'number', label: 'Course Soil' },
											{ type: 'number', label: 'Wind Gust Risk' },
										],
										...weatherData.filter(wx => wx.CropRiskFineSoil).map(wx => 
										{
											return [new Date(wx.Datestamp).toLocaleDateString('en-US', {
												day: 'numeric',
												month: 'short'
											}),
											growthStageByDate[new Date(wx.Datestamp).toDateString()], 
											wx.CropRiskFineSoil, 
											-100,
											-60,
											wx.CropRiskFineSoil, 
											-60,
											-20,
											wx.CropRiskFineSoil, 
											-20,
											20,
											wx.CropRiskFineSoil, 
											20,
											60,
											wx.CropRiskFineSoil, 
											60,
											100,
											wx.CropRiskFineSoil, 
											wx.CropRiskCourseSoil,
											wx.WindGustRisk > 0 ? wx.WindGustRisk : undefined,
											];
										})
									]}
									width='100%'
									height='70vh'
									legendToggle
								/>
								<div style={{position:'absolute', left: '8%', top: '20%', transform: 'rotate(-60deg)'}}>Hot & Dry</div>
								<div style={{position:'absolute', left: '9%', top: '49%', transform: 'rotate(-60deg)'}}>Mild</div>
								<div style={{position:'absolute', left: '8%', bottom: '20%', transform: 'rotate(-60deg)'}}>Cold & Wet</div>
								</div>},
								{ label: 'Temperature', key: 'temp', children:<Chart 
									chartType="LineChart"
									options={{
										series: [
											{ color: '#1B71E7' },
											{ color: '#E7711B' },
											{ color: '#999999' },
										],
										intervals: { 'color':'#E7711B', style: 'area', 'label': '20 yr avg' },	
										interval: {
											'i0': { 'style': 'area', 'curveType':'function', 'fillOpacity':0.3 }	
										},
										hAxis: { title: 'Date' },
										vAxes: {
											0: {logScale: false, title: 'Celsuis'},
										},
										backgroundColor: '#fff'
									}}
									data={[
										[
											{ type: 'string', label: 'Date' },
											{ type: 'number', label: 'Minimum' },
											{ type: 'number', label: 'Maximum' },
											{ type: 'number', label: 'Mean' },
											{ id: 'i0', type: 'number', label: '20 yr avg', role:'interval' },
											{ id: 'i1', type: 'number', label: '20 yr avg', role:'interval' },
											{ type: 'string', role: 'annotation'}
										],
										...weatherData.filter(wx => wx.CropRiskFineSoil).map(wx => 
										{
											return [new Date(wx.Datestamp).toLocaleDateString('en-US', {
												day: 'numeric',
												month: 'short'
											}), 
											wx.MinimumTemperature2mCelsius,
											wx.MaximumTemperature2mCelsius,
											(wx.MinimumTemperature2mCelsius + wx.MaximumTemperature2mCelsius)/2,
											wx.Climatology?.MinimumTemperatureCelsius,
											wx.Climatology?.MaximumTemperatureCelsius,
											growthStageByDate[new Date(wx.Datestamp).toDateString()]
											];
										})
									]}
									width='100%'
									height='70vh'
									legendToggle
								/> },
								{ label: 'Precipitation', key: 'precip', children:<Chart 
									chartType="LineChart"
									options={{
										series: [
											{ color: '#1B71E7', type: 'area' },
											{ color: '#0B21a7', type: 'area' },
										],
										intervals: { style: 'area' },	
										hAxis: { title: 'Date' },
										vAxes: {
											0: {logScale: false, title: 'mm'},
										},
										backgroundColor: '#fff'
									}}
									data={[
										[
											{ type: 'string', label: 'Date' },
											{ type: 'number', label: '20 yr avg'},
											{ type: 'number', label: 'Current' },
											{ type: 'string', role: 'annotation'}
										],
										...weatherData.filter(wx => wx.CropRiskFineSoil).map(wx => 
										{
											return [new Date(wx.Datestamp).toLocaleDateString('en-US', {
												day: 'numeric',
												month: 'short'
											}),
											wx.Climatology?.PrecipitationMillimeters, 
											wx.PrecipitationMillimeters,
											growthStageByDate[new Date(wx.Datestamp).toDateString()]
											];
										})
									]}
									width='100%'
									height='70vh'
									legendToggle
								/> },
								{ label: 'GDDs', key: 'gdd', children:<Chart 
									chartType="LineChart"
									options={{
										series: [
											{ color: '#a1a1a1' },
											{ color: '#E7711B' },
										],
										intervals: { style: 'area' },	
										hAxis: { title: 'Date' },
										vAxes: {
											0: {logScale: false, title: 'Growing Degree Units'},
										},
										backgroundColor: '#fff'
									}}
									data={[
										[
											{ type: 'string', label: 'Date' },
											{ type: 'number', label: '20 yr avg'},
											{ type: 'number', label: 'Current' },
											{ type: 'string', role: 'annotation'}
										],
										...weatherData.filter(wx => wx.CropRiskFineSoil).reduce((prev, wx) => 
										{
											const last = prev.length > 0
												? prev[prev.length-1] 
												: ['', 0, 0];

											return [
												...prev, 
												[
													new Date(wx.Datestamp).toLocaleDateString('en-US', {
														day: 'numeric',
														month: 'short'
													}),
													(last[1]) + wx.Climatology?.CornGrowingDegreeUnits, 
													(last[2]) + wx.CornGrowingDegreeUnits,
													growthStageByDate[new Date(wx.Datestamp).toDateString()]
												]
											];
										}, [] as IWeatherSnapshot[])
									]}
									width='100%'
									height='70vh'
									legendToggle
								/> },
								{ label: 'Combined', key: 'combined', children:<Chart 
									chartType="LineChart"
									options={{
										series: [
											{ color: '#999999', type: 'bars'},
											{ color: '#aaaaff', type: 'area', targetAxisIndex: 1, trendlineOpacity:0.2, areaOpacity: 0.2 },
											{ color: '#ffaaaa', type: 'lines', targetAxisIndex: 1, trendlineOpacity: 0.2 },
											{ color: '#1B71E7', lineWidth: 3 },
											{ color: '#E7711B', lineWidth: 3 },
										],
										intervals: { 'color':'#E7711B', style: 'area', 'label': '20 yr avg' },	
										interval: {
											'i0': { 'style': 'area', 'curveType':'function', 'fillOpacity':0.3 }	
										},
										hAxis: { title: 'Date' },
										vAxes: {
											0: {logScale: false, title: 'Crop Stress Index'},
											1: {minValue:0, maxValue:30, title: 'Weather'},
										},
										backgroundColor: '#fff'
									}}
									data={[
										[
											{ type: 'string', label: 'Date' },
											{ type: 'string', role: 'annotation'},
											{ type: 'number', label: 'Wind Gust Risk' },
											{ type: 'number', label: 'Precip' },
											
											{ type: 'number', label: 'Temperature' },
											{ id: 'i0', type: 'number',  role:'interval' },
											{ id: 'i1', type: 'number', role:'interval' },
											{ type: 'number', label: 'Fine Soil' },
											{ type: 'number', label: 'Course Soil' },

										],
										...weatherData.filter(wx => wx.CropRiskFineSoil).map(wx => 
										{
											return [new Date(wx.Datestamp).toLocaleDateString('en-US', {
												day: 'numeric',
												month: 'short'
											}), 		
											growthStageByDate[new Date(wx.Datestamp).toDateString()],
											wx.WindGustRisk > 0 ? wx.WindGustRisk : undefined,
											wx.PrecipitationMillimeters,
											(wx.MaximumTemperature2mCelsius+wx.MinimumTemperature2mCelsius)/2,
											wx.MinimumTemperature2mCelsius,
											wx.MaximumTemperature2mCelsius, 
											wx.CropRiskFineSoil, 
											wx.CropRiskCourseSoil, 									
											];
										})
									]}
									width='100%'
									height='70vh'
								/>}
							]} />
							
						</Modal> : <React.Fragment />
					}
					{(showSoilMapButton) ?
						<div className='MapPopUp_SeedContainer'
							style={{
								border: '1px solid',
								borderColor: theme.colors.darkGrey,
								marginTop: '3%',
								width: '100%',
								padding: '5%'
							}}
						>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<SeedLabelRow>
									<div>
										Current Crop
									</div>
									<div className='FieldCurrentCrop' style={{ marginLeft: 'auto' }}>
										{field.CurrentCrop}
									</div>
								</SeedLabelRow>
								<SeedLabelRow>
									<div>
										Planting Date
									</div>
									<div className='FieldCurrentCrop' style={{ marginLeft: 'auto' }}>
										{field.PlantingDate ? new Date(field.PlantingDate).toLocaleDateString(): 'N/A'}
									</div>
								</SeedLabelRow>
								{
									fieldSeeds && field.Seeds?.length > 0 && <SeedLabelRow>
										<div style={{ fontWeight: theme.fontWeights.bold }}>
											Hybrid/Variety
										</div>
										<div style={{ marginLeft: 'auto', fontWeight: theme.fontWeights.bold }}>
											Acres
										</div>
									</SeedLabelRow>
								}
								{fieldSeeds && Object.keys(fieldSeeds).sort().map((hybridName) =>
									<div key={`${field.Id}-${hybridName}`} style={{ paddingBottom: 5 }}>
										<SeedHybridParentRow
											className={`HybridParentRow_${field.Id}-${hybridName}`}
											style={{ backgroundColor: theme.colors.lightestGrey }}
										>
											<div
												style={{ fontWeight: theme.fontWeights.bold }}
												className={`HybridName_${field.Id}-${hybridName}`}
											>
												{hybridName}
											</div>
											<div
												style={{ fontWeight: theme.fontWeights.bold, marginLeft: 'auto' }}
												className={`TotalAcres_${field.Id}-${hybridName}`}
											>
												{fieldSeeds[hybridName].reduce((sum, fieldDetail) => sum + fieldDetail.Acres, 0).toFixed(2)}
											</div>
										</SeedHybridParentRow>										
										{fieldSeeds[hybridName].sort(dynamicSort('Treatment')).map((seedDetails) =>
											<SeedHybridRow
												key={`${field.Id}-${hybridName}-${seedDetails.Treatment}`}
												className={`Field_${field.Id}-${hybridName}-${seedDetails.Treatment}_SeedData`}
											>
												<div className={`SeedTreatmentName_${seedDetails.Treatment}`}>
													{seedDetails.Treatment}
												</div>
												<div className={`SeedTreatmentArea_${seedDetails.Treatment}`} style={{ marginLeft: 'auto' }}>
													{seedDetails.Acres.toFixed(2)}
												</div>
											</SeedHybridRow>
										)}
									</div>
								)}
							</div>
						</div>
						:
						<React.Fragment />
					}
					{
						(!showSoilMapButton) &&
						<div className='MapPopUp_SoilDataContainer'
							style={{
								marginTop: '3%',
								width: '100%',
							}}
						>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<div
									className='SoilDataTable_HeaderRow'
									style={{
										display: 'flex',
										flexDirection: 'row',
										fontWeight: theme.fontWeights.bold,
										paddingBottom: 5
									}}>
									<div style={{ width: 62 }}>
										NCCPI
									</div>
									<div style={{ width: 80 }}>
										Soil Zone
									</div>
									<div style={{ width: 50, textAlign: 'right' }}>
										Acres
									</div>
									<div style={{ marginLeft: 'auto' }}>
										% of Total
									</div>
								</div>
								<div
									className='SoilDataTable_DataRows'
									style={{ display: 'flex', flexDirection: 'column', maxHeight: 200, overflowY: 'auto' }}
								>
									{fieldSoilData.sort(dynamicSort('PercentOfTotal', 'descending')).map(fsd =>
										<div
											key={fsd.MapUnit}
											className={`SoilDataRow_${fsd.MapUnit}`}
											style={{
												display: 'flex',
												flexDirection: 'row',
												justifyContent: 'space-between',
												paddingBottom: 2
											}}
										>
											<div
												className='SoilData_Color_Overall'
												style={{ display: 'flex', flexDirection: 'row', width: 60 }}
											>
												<ColoredDot className='Color' color={fsd.Color} />
												<div className='Overall' style={{ paddingLeft: 4 }}>{fsd.OverallNccpi}</div>
											</div>
											<div className='SoilData_SoilType' style={{ width: 80 }}>{fsd.SoilType}</div>
											<div className='SoilData_Acres' style={{ width: 40, textAlign: 'right' }}>{fsd.Acres}</div>
											<div className='SoilData_PercentOfTotal' style={{ width: 60, textAlign: 'right' }}>{fsd.PercentOfTotal}%</div>
										</div>
									)}
								</div>
							</div>
						</div>
					}
					{/* Removed for now until previous crop/yield data is sent */}
					{/* <div className='MapPopUp_OtherDetailsContainer'
						style={{
							border: '1px solid',
							borderColor: theme.colors.darkGrey,
							marginTop: '3%',
							width: '100%',
							padding: '5%',
							marginBottom: '5%'
						}}
					>
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<div style={{ display: 'flex', flexDirection: 'row', marginBottom: '2%' }}>
								<div>
									Previous Crop
								</div>
								<div style={{ marginLeft: 'auto' }}>
									Soybean
								</div>
							</div>
							<div style={{ display: 'flex', flexDirection: 'row', marginBottom: '2%' }}>
								<div>
									Predicted Yield Corn
								</div>
								<div style={{ marginLeft: 'auto' }}>
									3,800
								</div>
							</div>
							<div style={{ display: 'flex', flexDirection: 'row', marginBottom: '2%' }}>
								<div>
									Event Count
								</div>
								<div style={{ marginLeft: 'auto' }}>
									341
								</div>
							</div>
							<div style={{ display: 'flex', flexDirection: 'row', marginBottom: '2%' }}>
								<div>
									Growth Stage
								</div>
								<div style={{ marginLeft: 'auto' }}>
									---
								</div>
							</div>
						</div>
					</div> */}
				</div>
				{/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
					<div style={{ borderBottom: '1px solid', borderColor: theme.colors.darkGrey, width: '100%' }} />
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', width: '100%', marginTop: '5%' }}>
						<Button style={{ width: '45%' }} variant='outlined'>Edit</Button>
						<Button style={{ width: '45%' }} variant='outlined'>Machine Data</Button>
					</div>
				</div> */}
			</PortalPopUp>
			:
			<React.Fragment />
	);
};